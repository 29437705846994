import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "caring-for-yourself"
    }}>{`Caring for yourself`}</h2>
    <p>{`Parenting is tough at the best of times. With the added challenges of the pandemic, it is more important than ever to look after yourself. When you prioritise your own emotional and physical needs, you are in a better position to give comfort and care to your teenager.`}</p>
    <p>{`Remember before the pandemic, when we used to travel by plane? In case of emergency, we are always instructed to fit our own oxygen mask before helping others. This is called the `}<b>{`Oxygen Mask Principle`}</b>{`, and it can also be applied to parenting.`}</p>
    <p>{`The way you manage and express your own stress can impact how your teen feels, and how they cope with the pandemic. So, before we look at strategies about parenting during the pandemic, we will quickly touch on looking after yourself.`}</p>
    <SingleImage smallGridSize={12} gridSize={10} src="/images/m2/3-plane.svg" alt="helping with oxygen mask in plane" mdxType="SingleImage" />
    <p>{`This section will cover:`}</p>
    <ul>
      <li parentName="ul">{`Self-care activities`}</li>
      <li parentName="ul">{`Asking for help and support`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      